<template>
    <v-row>
        <div class="alert alterar mt-1">
            <p>
                O campo abaixo trata-se de um formato de edição markdown. Mantém o registro em texto legível no banco de dados
                mas pode ser facilmente customizado. Para maiores informações em como utilizar, <a
                    href="https://pt.wikipedia.org/wiki/Markdown" target="_blank">Clique aqui.</a>
            </p>
        </div>
        <v-col cols="6">
            <CTextarea rows="20" label="Informações Técnicas" v-model="item.infoTecnicas" />
        </v-col>
        <v-col cols="6">
            <strong>Prévia</strong>
            <vue-markdown :source="item.infoTecnicas" />
        </v-col>
    </v-row>
</template>

<script>
  export default {
    name: "InformacoesTecnicas",
    props: [
      'item'
    ]
  }
</script>

<style scoped>

</style>