<template>
    <v-row>
        <v-col cols="12">
            <CTextarea rows="20" label="Argumento de Venda" v-model="item.argVenda" />
        </v-col>
    </v-row>
</template>

<script>
  export default {
    name: "ArgumentoVenda",
    props: [
      'item'
    ]
  }
</script>

<style scoped>

</style>