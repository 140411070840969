<template>
    <div>
        <Edit
            :item="currentItem"
            :close="closeEdit"
            :onSave="saveProduto"
        />
        <Loading :isActive="loading"></Loading>
        <CTabs variant="tabs" class="nav-tabs-boxed">
        <CTab title="Produtos">
            <div class="filters row" style="margin-top:0">
                <div class="col-1-1">
                  <span class="filters__search">
                    <input
                            type="text"
                            v-model="termCod"
                            placeholder="Código"
                            v-on:keyup.enter="loadProdutos"
                    />
                  </span>
                    <span> ou </span>
                    <span class="filters__search">
                    <input
                            type="text"
                            v-model="termDesc"
                            placeholder="Descrição"
                            v-on:keyup.enter="loadProdutos"
                    />
                  </span>
                    <button class="button button-primary ml-1" v-on:click="loadProdutos()">
                        Buscar
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <datatable :columns="table.columns" :data="items" class="table table-hover">
                    <template slot-scope="{ row }">
                        <tr>
                            <td class="highlight">{{ row.id }}</td>
                            <td>
                                <img :src="cdnUrl(row.id)" class="thumbnail thumbnail-extra-small">
                            </td>
                            <td>{{ row.descComp }}</td>
                            <td class="actions">
                                <button type="button" class="button button-primary" v-on:click="openEdit(row)">
                                    <fa-icon icon="pencil-alt" />
                                </button>
                            </td>
                        </tr>
                    </template>
                </datatable>
            </div>
            <div style="display: flex; justify-content: center;">
                <pagination
                        :data="table.paginator"
                        :limit="5"
                        @pagination-change-page="loadProdutos"
                ></pagination>
            </div>
        </CTab>
    </CTabs>
    </div>
</template>

<script>
import clone from 'just-clone';
import _map from 'lodash/map';
import TemplateModulo from '../../components/TemplateModulo';
import { get, post, put, del } from '@/helpers/apiRequest';
import Edit from './Edit';
import { cdnUrlProduto } from '../../helpers/url';
import { makeHash } from '../../helpers/utils';

export default {
  name: 'produtos',
  components: {
    Edit,
    TemplateModulo,
  },
  data() {
    return {
      currentItem: {},
      loading: false,
      error: false,
      items: [],
      termDesc: '',
      termCod: '',
      currentPage: 1,
      currentHash: makeHash(),
      table: {
        filter: '',
        columns: [
          { label: 'ID', field: 'id', align: 'center', sortable: false },
          { label: 'Imagem', field: 'imagem', sortable: false },
          { label: 'Descrição', field: 'descNF', sortable: false },
          { label: '', field: '', sortable: false },
        ],
        paginator: {},
      },
    };
  },
  methods: {
    loadProdutos(page = 1) {
      this.currentPage = page;
      this.currentHash = makeHash();

      get(
        `/admin/produtos?page=${page}&filtros[produto_id]=${
          this.termCod
        }&filtros[termo]=${this.termDesc}`
      )
        .then((json) => {
          this.items = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    refreshProdutos() {
      this.loadProdutos(this.currentPage);
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.currentItem.infoTecnicas = this.currentItem.infoTecnicas || "";
      this.currentItem.dadosTecnicos = this.currentItem.dadosTecnicos || "";
      this.$modal.show('produtoEdit');
    },
    closeEdit() {
      this.$modal.hide('produtoEdit');
      this.refreshProdutos();
    },
    saveProduto() {
      const produto = normalizeProdutoSalvar(this.currentItem);
      put(`/admin/produtos/${produto.id}`, produto)
        .then((json) => {
          this.closeEdit()
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    },
    cdnUrl(id) {
      return cdnUrlProduto(id, this.currentHash);
    },
  },
  beforeMount() {
    this.loadProdutos();
  },
};

function normalizeProdutoSalvar(produto) {
  return {
    ...produto,
    similares: _map(produto.similares, 'id'),
  };
}
</script>
