<style scoped>
    .similar-wrapper {
        border: 1px solid rgba(0, 0, 0, 0.2);
        min-height: 310px;
    }

    .similar-filter-wrapper {
        padding: 12px;
    }

    .similar-list-wrapper {
        max-height: 250px;
        overflow: auto;
    }
</style>
<template>
    <v-row>
        <v-col cols="12">
            <div class="similar-wrapper">
                <div class="similar-filter-wrapper" style="margin-top:0">
              <span class="filters__search">
                <input type="text" v-model="termCod" placeholder="Código" v-on:keyup.enter="loadProdutos" />
              </span>
                    <span> ou </span>
                    <span class="filters__search">
                <input type="text" v-model="termDesc" placeholder="Descrição" v-on:keyup.enter="loadProdutos" />
              </span>
                    <button class="button button-primary ml-1" v-on:click="loadProdutos()">
                        Pesquisar produtos
                    </button>
                </div>
                <div class="similar-list-wrapper">
                    <div class="table-responsive">
                        <datatable :columns="table.columns" :data="items" class="table table-hover">
                            <template slot-scope="{ row }">
                                <tr>
                                    <td class="highlight">{{ row.id }}</td>
                                    <td style="text-align:center">
                                        <img :src="cdnUrl(row.id)" class="thumbnail thumbnail-extra-small">
                                    </td>
                                    <td>{{ row.descComp }}</td>
                                    <td class="actions">
                                        <button type="button" :disabled="jaAdicionado(row.id)" class="button button-primary" v-on:click="addSimilar(row)">
                                            <fa-icon icon="plus" />
                                        </button>
                                    </td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                </div>
            </div>
        </v-col>
        <v-col cols="12">
            <div class="similar-wrapper">
                <div class="similar-filter-wrapper">
                    <h6>Produtos Similares</h6>
                </div>
                <div class="similar-list-wrapper">
                    <div class="table-responsive">
                        <datatable :columns="table.columns" :data="similaresAdicionados" class="table table-hover">
                            <template slot-scope="{ row }">
                                <tr>
                                    <td class="highlight">{{ row.id }}</td>
                                    <td style="text-align:center">
                                        <img :src="cdnUrl(row.id)" class="thumbnail thumbnail-extra-small">
                                    </td>
                                    <td>{{ row.descComp }}</td>
                                    <td class="actions">
                                        <button type="button" class="button button-error" v-on:click="removeSimilar(row.id)">
                                            <fa-icon icon="trash-alt" />
                                        </button>
                                    </td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
  export default {
    name: "Similares",
    props: [
      'items',
      'table',
      'similaresAdicionados',
      'removeSimilar',
      'addSimilar',
      'termCod',
      'termDesc',
      'loadProdutos',
      'cdnUrl',
    ],
  }
</script>

<style scoped>

</style>