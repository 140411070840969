<style scoped>
.add-image {
  margin-bottom: 10px;
}
</style>
<template>
  <div>
    <div>
      <div class="alert remover" v-show="!!errorMessage">
        <a href="#" class="close" @click="errorMessage = null">&times;</a>
        Falha ao Salvar alterações:<br />
        <p>{{ errorMessage }}</p>
      </div>
      <div class="">
        <br />
        <div class="alert alterar">
          <p>
            Para que a imagem principal funcione corretamente, o nome da imagem
            deverá ser o mesmo código do produto. Caso contrário, a imagem será
            considerada secundária. Exemplo: código do produto:
            <strong>{{ item.id }}</strong
            >, nome da imagem: <strong>{{ item.id }}.jpg</strong>
            <br />
            Obs: Ao fazer o upload da imagem, ela já é salva diretamente no
            repositório de imagens.
          </p>
        </div>
      </div>
    </div>
    <div>
      <label class="button success">
        Adicionar Imagem
        <input
          type="file"
          name="image"
          style="display: none"
          accept="image/jpg, image/jpeg, image/png"
          @change="upload"
        />
      </label>
    </div>
    <div class="d-flex flex-wrap">
      <div v-for="imagem in imagens" class="col-4">
        <div class="thumbnail BorderActive">
          <div class="subtitle">
            <strong class="small dark">{{ imagem | baseName }}</strong>
          </div>
          <div class="LogoimgBox">
            <img :src="imagem" />
          </div>
          <div class="caption">
            <div class="caption__actions">
              <label class="button error" @click="remove(imagem)">
                Excluir
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Imagens",
  props: ["item", "imagens", "remove", "upload", "errorMessage"],
};
</script>

<style scoped></style>
